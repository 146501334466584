import { Modal } from "@mui/material";
import { useState } from "react";
import styled from "styled-components";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { useBreakpoint } from "../../../hooks/useBreakpoint";
import { Breakpoint } from "../../../types/enum.types";
import Eticket from "../../Eticket";
import { companyLogosData } from "./utils/constants";

const CompanyLogosContainer = styled.div`
  width: 100%;
  background-color: var(--color-primary-light);
  padding: 50px 50px 0 50px;

  p {
    font-size: 28px;
    font-family: var(--font-title-bold);
    text-align: center;
    margin-bottom: 0;
  }

  .company_logos_content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .company_logos_carousel {
    position: relative;
    width: 80%;
  }

  .reverse {
    transform: rotate(180deg);
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
  }

  .swiper-wrapper {
    align-items: center;
  }
`;

const NavigationIcon = styled.div<{ right?: boolean }>`
  background-color: var(--color-primary);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: ${(props) => (props.right ? "-10%" : "unset")};
  left: ${(props) => (props.right ? "unset" : "-10%")};
  top: 40%;
  cursor: pointer;

  img {
    width: 20px;
  }

  @media screen and (max-width: 992px) {
    right: ${(props) => (props.right ? "-25%" : "unset")};
    left: ${(props) => (props.right ? "unset" : "-25%")};
  }
`;

const CarouselContainer = styled.div`
  margin-top: 50px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  .company_logo {
    width: 100px;
  }

  .company_cursor {
    cursor: pointer;
  }
`;

const AudioContainer = styled.div`
  border: none;
  outline: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .audio {
    outline: none;
    border: none;
  }
`;

const CompanyLogos = () => {
  const [selectedAudio, setSelectedAudio] = useState<string>();

  const navigation = {
    nextEl: "#nextCompanyButton",
    prevEl: "#prevCompanyButton",
  };

  const breakpoint = useBreakpoint();
  const isXlarge = breakpoint.includes(Breakpoint.XL);
  const isLarge =
    breakpoint.includes(Breakpoint.LG) && !breakpoint.includes(Breakpoint.XL);
  const isMedium =
    breakpoint.includes(Breakpoint.SM) && !breakpoint.includes(Breakpoint.LG);
  const isSmall =
    breakpoint.includes(Breakpoint.XS) && !breakpoint.includes(Breakpoint.SM);

  return (
    <CompanyLogosContainer>
      <p>
        IIs parlent de
        <Eticket
          text="nous"
          backgroundColor="var(--color-primary)"
          color="var(--color-dark-1) !important"
          padding="0 10px"
        />
      </p>
      <div className="company_logos_content">
        <div className="company_logos_carousel">
          <NavigationIcon id="prevCompanyButton">
            <img
              className="reverse"
              src={"/assets/icons/right-arrow-white.svg"}
            />
          </NavigationIcon>
          <CarouselContainer>
            {(isLarge || isMedium || isXlarge || isSmall) && (
              <Swiper
                threshold={10}
                spaceBetween={0}
                loop
                slidesPerView={isXlarge ? 4 : isLarge ? 3 : isMedium ? 2 : 1}
                navigation={navigation}
                modules={[Navigation]}
              >
                {companyLogosData.map((company, index) => (
                  <SwiperSlide key={index}>
                    <a href={company.link} target="_blank" rel="noreferrer">
                      <img
                        onClick={() => {
                          if (company.audioSrc)
                            setSelectedAudio(company.audioSrc);
                        }}
                        className={`company_logo ${
                          (company.link || company.audioSrc) && "company_cursor"
                        }`}
                        style={{ width: company.width }}
                        src={company.logoPath}
                      />
                    </a>
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </CarouselContainer>

          <NavigationIcon right id="nextCompanyButton">
            <img src={"/assets/icons/right-arrow-white.svg"} />
          </NavigationIcon>
        </div>
      </div>
      <Modal
        open={!!selectedAudio}
        onClose={() => {
          setSelectedAudio(undefined);
        }}
      >
        <AudioContainer>
          <audio controls autoPlay src={selectedAudio} />
        </AudioContainer>
      </Modal>
    </CompanyLogosContainer>
  );
};

export default CompanyLogos;
