import "swiper/css";
import "swiper/css/pagination";

import { useRouter } from "next/router";
import { LazyLoadImage } from "react-lazy-load-image-component";
import styled from "styled-components";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { Offer } from "../../../types/offer.type";
import { getOfferImage } from "../../../utils/functions.utils";
import { OFFER_PAGE, OFFERS_PAGE } from "../../../utils/pages";
import {
  formatDate,
  formatOfferSalary,
  formatPublishedDate,
} from "../../../utils/utils";
import PrimaryButton from "../../buttons/PrimaryButton";
import Eticket from "../../Eticket";
import { RoundedSvg } from "../utils/styles";
import VerticalCard from "../utils/VerticalCard";
import styles from "./LatestOffers.module.scss";

const CardPosition = styled.div`
  width: 90%;
  margin-left: 10px;
  height: 100%;

  a {
    text-decoration: none;
    color: inherit;
  }
`;

const navigation = {
  nextEl: "#nextOfferButton",
  prevEl: "#prevOfferButton",
};

const breakpoints = {
  0: {
    slidesPerView: 1.4,
    spaceBetween: 0,
  },
  768: {
    slidesPerView: 2.4,
    spaceBetween: 0,
  },
  1024: {
    slidesPerView: 3.4,
    spaceBetween: 0,
  },
  1200: {
    slidesPerView: 4.4,
    spaceBetween: 0,
  },
  3000: {
    slidesPerView: 4.2,
    spaceBetween: 0,
  },
  4000: {
    slidesPerView: 5,
    spaceBetween: 0,
  },
};

const Button = styled(PrimaryButton)`
  font-family: var(--font-title-bold);
`;
const CarouselContainer = styled.div`
  width: 95%;
  margin: 15px 0 0 auto;
`;

interface Props {
  offers: Offer[];
}

const LatestOffers: React.FC<Props> = ({ offers }) => {
  const router = useRouter();

  const handleSeeAll = () => {
    router.push(OFFERS_PAGE);
  };

  return (
    <div>
      <br />
      {/* slider part */}
      <div className={styles.latest_offers_header}>
        <div className={styles.latest_offers_header_left_part}>
          <RoundedSvg
            color="var(--color-primary)"
            className={styles.roundedSVG}
          >
            <LazyLoadImage src="/assets/icons/bag.svg" alt="bag" />
          </RoundedSvg>
          <div className={styles.latest_offers_title}>
            <h2 className={styles.stickerTitle}>
              LES DERNIÈRES
              <Eticket
                text="OFFRES"
                color="var(--color-dark-1)"
                backgroundColor="var(--color-primary)"
              />
            </h2>
          </div>
        </div>

        <div className={styles.latest_offers_header_right_part}>
          <Button
            label="Tout voir"
            width="100px"
            padding="10px"
            onClick={handleSeeAll}
          />
        </div>
      </div>
      <br />
      <br />

      <div className={styles.container}>
        <CarouselContainer>
          <Swiper
            threshold={10}
            slidesPerView={4.2}
            spaceBetween={0}
            navigation={navigation}
            className={styles.swiperWrapperClass}
            modules={[Navigation]}
            breakpoints={breakpoints}
          >
            {offers.map((offer: Offer) => (
              <SwiperSlide key={offer.id} className={styles.maxHeight}>
                <CardPosition>
                  <a href={`${OFFER_PAGE}${offer.slug}/`} rel="noreferrer">
                    <VerticalCard
                      mark={formatPublishedDate(offer.publishedAt)}
                      contracts={offer.contractTypes}
                      location={offer.localization?.city}
                      price={formatOfferSalary(
                        offer.minSalary,
                        offer.maxSalary,
                        offer.salaryRate
                      )}
                      title={offer.title}
                      time={formatDate(new Date(offer.createdAt))}
                      image={getOfferImage(offer, true)}
                      company={offer.company?.name ?? offer.companyName}
                    />
                  </a>
                </CardPosition>
              </SwiperSlide>
            ))}
          </Swiper>
        </CarouselContainer>
      </div>
    </div>
  );
};

export default LatestOffers;
