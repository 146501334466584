function Contact() {
  return (
    <svg
      width="27"
      height="24"
      viewBox="0 0 27 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.3125 0H6.75C5.8166 0 5.0625 0.767679 5.0625 1.71429V19.7143C5.0625 20.1857 4.68492 20.5714 4.21875 20.5714C3.75258 20.5714 3.375 20.1857 3.375 19.7143V3.42857H1.6875C0.755684 3.42857 0 4.19464 0 5.14286V20.5714C0 22.4652 1.51084 24 3.375 24H23.625C25.4892 24 27 22.4652 27 20.5714V1.71429C27 0.767679 26.2459 0 25.3125 0ZM14.3438 20.5714H9.28125C8.81719 20.5714 8.4375 20.1857 8.4375 19.7143C8.4375 19.2429 8.81719 18.8571 9.28125 18.8571H14.3438C14.8097 18.8571 15.1875 19.2408 15.1875 19.7143C15.1875 20.1857 14.8078 20.5714 14.3438 20.5714ZM14.3438 15.4286H9.28125C8.81719 15.4286 8.4375 15.0429 8.4375 14.5714C8.4375 14.1 8.81719 13.7143 9.28125 13.7143H14.3438C14.8078 13.7143 15.1875 14.1 15.1875 14.5714C15.1875 15.0429 14.8078 15.4286 14.3438 15.4286ZM22.7812 20.5714H17.7188C17.2528 20.5714 16.875 20.1876 16.875 19.7143C16.875 19.2408 17.2528 18.8571 17.7188 18.8571H22.7812C23.2472 18.8571 23.625 19.2408 23.625 19.7143C23.625 20.1857 23.2453 20.5714 22.7812 20.5714ZM22.7812 15.4286H17.7188C17.2547 15.4286 16.875 15.0429 16.875 14.5714C16.875 14.1 17.2547 13.7143 17.7188 13.7143H22.7812C23.2453 13.7143 23.625 14.1 23.625 14.5714C23.625 15.0429 23.2453 15.4286 22.7812 15.4286ZM23.625 9.42857C23.625 9.9 23.2453 10.2857 22.7812 10.2857H9.28125C8.81719 10.2857 8.4375 9.9 8.4375 9.42857V4.28571C8.4375 3.81429 8.81719 3.42857 9.28125 3.42857H22.7812C23.2453 3.42857 23.625 3.81429 23.625 4.28571V9.42857Z"
        fill="white"
      />
    </svg>
  );
}

export default Contact;
