import "swiper/css";
import "swiper/css/pagination";

import styled from "styled-components";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { Article } from "../../../types/article.type";
import { ARTICLES_PAGE } from "../../../utils/pages";
import { formatArticleDate } from "../../../utils/utils";
import Contact from "../../assets/icons/Contact";
import Eticket from "../../Eticket";
import { RoundedSvg } from "../utils/styles";
import VerticalCard from "../utils/VerticalCard";
import styles from "./Articles.module.scss";

const breakpoints = {
  0: {
    slidesPerView: 1.4,
    spaceBetween: 0,
  },
  768: {
    slidesPerView: 2.4,
    spaceBetween: 0,
  },
  1024: {
    slidesPerView: 3.4,
    spaceBetween: 0,
  },
  1200: {
    slidesPerView: 4.4,
    spaceBetween: 0,
  },
  3000: {
    slidesPerView: 4.2,
    spaceBetween: 0,
  },
  4000: {
    slidesPerView: 5,
    spaceBetween: 0,
  },
};

const CardPosition = styled.div`
  width: 85%;
  height: 100%;

  a {
    text-decoration: none;
    color: inherit;
  }
`;

export const CarouselContainer = styled.div`
  width: 97%;
  margin: 20px 0 0 auto;
`;

interface Props {
  articles: Article[];
}

const Articles: React.FC<Props> = ({ articles }) => {
  const navigation = {
    nextEl: "#nextButton",
    prevEl: "#prevButton",
  };
  return (
    <div className={styles.applications_container}>
      {/* slider part */}
      <div className={styles.applications_header}>
        <div className={styles.applications_header_left_part}>
          <RoundedSvg
            color="var(--color-primary)"
            className={styles.roundedSVG}
          >
            <Contact />
          </RoundedSvg>
          <h2 className={styles.applications_title}>
            Les derniers
            <Eticket
              text="ARTICLES"
              color="white"
              backgroundColor="var(--color-primary)"
            />
          </h2>
        </div>

        <div className={styles.applications_header_right_part}>
          {/* <PrimaryButton
            label="Tout voir"
            width="100px"
            padding="10px"
            onClick={handleSeeAll}
            style={{ fontFamily: "var(--font-title-bold)" }}
          /> */}
        </div>
      </div>

      {/* offers part */}
      <CarouselContainer>
        <Swiper
          threshold={10}
          slidesPerView={4}
          spaceBetween={0}
          navigation={navigation}
          modules={[Navigation]}
          breakpoints={breakpoints}
        >
          {articles.map((article) => (
            <SwiperSlide
              key={article.id}
              style={{
                height: "auto",
              }}
            >
              <CardPosition>
                <a href={`${ARTICLES_PAGE}${article.slug}/`} rel="noreferrer">
                  <VerticalCard
                    backgroundColor="white"
                    color="var(--color-dark-1)"
                    article={"Lire l’article"}
                    title={`${article.title.substring(0, 100)}${
                      article.title.length > 100 ? "..." : ""
                    }`}
                    time={formatArticleDate(new Date(article.updatedAt))}
                    image={article.picturePath}
                  />
                </a>
              </CardPosition>
            </SwiperSlide>
          ))}
        </Swiper>
      </CarouselContainer>
    </div>
  );
};

export default Articles;
