import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import styled from "styled-components";

import { ContractType } from "../../../../types/select.type";
import { formatContractTypes } from "../../../../utils/utils";
import styles from "./index.module.scss";

const VerticalCardContainer = styled.div<{
  backgroundColor?: string;
  color?: string;
}>`
  width: 100%;
  background-color: ${(props) =>
    props.backgroundColor || "var(--color-dark-3)"};
  color: ${(props) => props.color || "white"};
  font-family: var(--font-title-bold);
  border-radius: 7px;
  cursor: pointer;
  height: 100%;

  img {
    width: 100%;
    height: 264px;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
  }
`;

const PositionRelative = styled.div`
  position: relative;
  // min-height: 230px;
  .lazy-load-image-background {
    width: 100%;
  }
  img {
    height: 230px;
    object-fit: cover;

    /* medium  */
    @media screen and (max-width: 768px) {
      height: 180px;
    }
  }
`;

const Mark = styled.div`
  position: absolute;
  padding: 7px 15px;
  background-color: var(--color-dark-1);
  border-radius: 7px;
  font-size: 80%;
  top: 10%;
  left: -2%;
`;

const VerticalCardContent = styled.div`
  padding: 0 20px;
  p {
    font-family: var(--font-title-light);
    font-weight: bolder;
    font-size: 80%;
  }

  .companyName,
  h3 {
    display: block;
    margin-top: 10px;
    font-size: 1em;
    font-weight: bolder;
    font-family: var(--font-title-bold);
  }
`;

const VerticalCardButtons = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const Button = styled.div<ButtonProps>`
  margin-right: 10px;
  margin-bottom: 10px;

  border-radius: 7px;
  border: none;
  outline: none;
  padding: 9px 12px;

  /* large  */
  @media screen and (max-width: 992px) {
    padding: 7px 10px;
  }

  display: flex;
  align-items: center;
  justify-content: center;
  p {
    font-size: 70%;
    margin: 0 0 0 10px;
    color: white;
    font-family: var(--font-title-bold);
  }

  img {
    width: 12px;
    height: 12px;
  }
  ${({ similar }) =>
    similar
      ? "background-color:#00daa2;"
      : "background-color: var(--color-primary);"}
`;
interface ButtonProps {
  similar?: boolean;
}
interface Props {
  image: string;
  company?: string;
  time?: string;
  title?: string;
  mark?: string;
  price?: string;
  contracts?: ContractType[];
  location?: string;
  article?: string;
  backgroundColor?: string;
  color?: string;
  onClick?: () => void;
}

const VerticalCard: React.FC<Props> = (props) => {
  return (
    <VerticalCardContainer
      backgroundColor={props.backgroundColor}
      color={props.color}
      onClick={props.onClick}
    >
      <PositionRelative className={styles.imageBox}>
        <LazyLoadImage
          effect="blur"
          src={props.image}
          alt={`Offre d’emploi ${props.title}`}
        />
        {props.mark && <Mark>{props.mark}</Mark>}
      </PositionRelative>

      <VerticalCardContent>
        <span className="companyName">{props.company}</span>
        <p>{props.time}</p>
        <h3>{props.title}</h3>

        <VerticalCardButtons>
          {props.contracts && (
            <Button similar>
              <LazyLoadImage
                effect="blur"
                src="/assets/icons/contractType.svg"
                alt="contract"
              />
              <p>{formatContractTypes(props.contracts)}</p>
            </Button>
          )}

          {props.location && (
            <Button similar>
              <LazyLoadImage
                effect="blur"
                src="/assets/icons/locationWhite.svg"
                alt="location"
              />
              <p>{props.location}</p>
            </Button>
          )}

          {props.price && (
            <Button similar>
              <LazyLoadImage
                effect="blur"
                src="/assets/icons/coins.svg"
                alt="coins"
              />
              <p>{props.price}</p>
            </Button>
          )}
        </VerticalCardButtons>
      </VerticalCardContent>
    </VerticalCardContainer>
  );
};

export default VerticalCard;
