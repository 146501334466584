import { useRouter } from "next/router";
import { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { OFFERS_PAGE } from "../../../utils/pages";
import Eticket from "../../Eticket";
import styles from "./ReceiveOffers.module.scss";

function ReceiveOffers() {
  const router = useRouter();
  const [expanded, setExpanded] = useState(false);

  return (
    <div className={styles.outerContainer}>
      <div className={styles.container}>
        <h2>
          À vous qui cherchez un
          <Eticket
            text="emploi"
            color="var(--color-dark-1) !important"
            backgroundColor="var(--color-primary)"
          />{" "}
          !
        </h2>
        <p className={styles.text}>
          À toi : chef de service médical, assistant médical, directeur médical,
          médecin généraliste, pharmacien, responsable médical, médecin
          coordonnateur, cadre de santé, chef de projet clinique, directeur des
          soins, directeur d'établissement médico-social, responsable
          scientifique régional...
          <br />
          <br />
          Trouve les entreprises et le métier de tes rêves, quel que soit le
          type de contrat : CDI, CDD, alternance, stage, intérim, vacation ou
          libéral !
        </p>
        <div>
          <button
            className={styles.empty_button}
            onClick={() => {
              router.push(OFFERS_PAGE);
            }}
          >
            Voir les offres
          </button>
        </div>
        <div
          className={[styles.icon, expanded ? styles.closed : styles.open].join(
            " "
          )}
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          <LazyLoadImage src={"/assets/images/expand.svg"} />
        </div>
      </div>
      <p className={`${styles.expanded} ${expanded ? "" : "nodisplay"}`}>
        Vous êtes candidat et vous avez un nouveau projet professionnel dans le
        secteur du médical ? Que vous recherchiez un CDI ou un CDD, découvrez
        les entreprises du secteur médical qui recrutent du personnel de santé
        dans leur équipe ! Parcourez les nombreuses offres d'emploi postées par
        les professionnels de santé et découvrez leur page entreprise pour en
        savoir davantage sur leur établissement, leur équipe et leurs missions !
        <br />
        <br />
        Créez votre profil candidat pour postuler et suivre vos candidatures, et
        trouvez des offres d'emploi santé qui vous correspondent grâce à
        l'algorithme de matching. Cet algorithme pourra vous aider dans votre
        recherche d'emploi car il fera une évaluation automatique de votre
        compatibilité avec l'entreprise et les missions qu'elle propose ! Vous
        recherchez un CDI ou un CDD en tant que directeur d'établissement
        médico-social à Saint-Denis ou aux alentours de Paris ? Rien de plus
        simple : rendez-vous dans "Offres" et filtrez selon vos critères de
        recherches : profession (directeur d'établissement médico-social),
        localisation (Saint-Denis), salaire souhaité, type de contrat (CDI et/ou
        CDD), secteur (Hôpitaux et centres de soins), date de publication et
        langues.
      </p>
    </div>
  );
}

export default ReceiveOffers;
