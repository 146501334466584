import React from "react";

interface Props {
  color?: string;
  width?: number;
  height?: number;
  style?: React.CSSProperties;
}

const ArrowDown: React.FC<Props> = ({
  color,
  width = 15,
  height = 9,
  style,
}) => {
  return (
    <svg
      style={style}
      width={width}
      height={height}
      viewBox="0 0 13 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.49992 5.86162L11.3331 1.02842C11.7144 0.647101 12.3327 0.647101 12.714 1.02842C13.0953 1.40975 13.0953 2.02799 12.714 2.40931L7.19744 7.92588C7.19511 7.92826 7.19276 7.93063 7.1904 7.93299C6.80908 8.31432 6.19083 8.31432 5.80951 7.93299L0.285944 2.40943C-0.0953789 2.02811 -0.0953789 1.40986 0.285944 1.02854C0.667266 0.647215 1.28551 0.647215 1.66683 1.02854L6.49992 5.86162Z"
        fill={color || "#2D3C3B"}
      />
    </svg>
  );
};

export default ArrowDown;
