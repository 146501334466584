import { ICompanyLogo } from "../../../../types/company.type";

export const companyLogosData: ICompanyLogo[] = [
  {
    logoPath: "/assets/images/companyLogos/12._BFM_BUSINESS.png",
    link: "https://www.bfmtv.com/economie/replay-emissions/soixante-minutes-business/90-minutes-business-avec-vous-du-mercredi-3-mai_VN-202305030543.html",
    width: 70,
  },
  {
    logoPath: "/assets/images/companyLogos/1._FORBES.png",
    link: "https://www.forbes.fr/business/comment-faire-de-sa-marque-employeur-un-atout-strategique-pour-les-acteurs-de-la-sante/",
    width: 120,
  },
  {
    logoPath: "/assets/images/companyLogos/4._jaimelesstartups.png",
    link: "https://www.jaimelesstartups.fr/news/swala-lance-la-plateforme-de-recrutement-pour-les-metiers-de-la-sante/",
    width: 120,
  },
  {
    logoPath: "/assets/images/companyLogos/6._AQUITAINE_ONLINE.png",
    link: "https://www.aquitaineonline.com/actualites-en-aquitaine/bordeaux-metropole/10387-swala-plateforme-de-recrutement-dediee-aux-acteurs-de-la-sante.html",
    width: 150,
  },
  {
    logoPath: "/assets/images/companyLogos/11._BORDEAUX_BUSINESS2.png",
    link: "https://bordeaux.business/swala-solution-recrutement-sante/",
    width: 100,
  },
  {
    logoPath: "/assets/images/companyLogos/10._BUSINESS_COOL.png",
    link: "https://business-cool.com/actualites/actu-business/swala-recrutement-pros-sante/",
    width: 80,
  },
  {
    logoPath: "/assets/images/companyLogos/2._PLACECO.png",
    link: "https://www.placeco.fr/gironde/demain/article/swala-veut-travailler-la-marque-employeur-des-entreprises-de-la-sante-2406",
    width: 130,
  },
  {
    logoPath: "/assets/images/companyLogos/7._RTL2.png",
    audioSrc: "/assets/audio/RTL_audio.mp3",
    width: 80,
  },
  {
    logoPath: "/assets/images/companyLogos/8._TV7.png",
    link: "https://www.sudouest.fr/lachainetv7/economie/modes-d-emplois/videos/2023/03/23/qr3kzm5#:~:text=Dans%20un%20contexte%20marqu%C3%A9%20par,d%C3%A9fi%20%C3%A0%20relever%20en%202021*",
    width: 100,
  },
  {
    logoPath: "/assets/images/companyLogos/9._LES_ECHOS.png",
    link: "https://www.echos-judiciaires.com",
    width: 120,
  },
  {
    logoPath: "/assets/images/companyLogos/5._LOGOAPS.png",
    link: "http://www.aquitainepresse.com",
    width: 100,
  },
  {
    logoPath: "/assets/images/companyLogos/3._L_ESSENTIEL_.png",
    link: "https://lessentiel-bordeaux.fr",
    width: 100,
  },
];
